<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hasFocus: false,
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
    },
}
</script>

<template>
    <div :class="{ hasFocus: hasFocus }">
        <BaseLabel :field="field" />
        <input
            :id="field.name"
            type="file"
            :name="field.name"
            accept="image/*"
            :required="field.required"
            :placeholder="field.placeholder"
            class="input"
            :class="$style.input"
            v-on="listeners"
            @focus="hasFocus = true"
            @blur="hasFocus = false"
        />
    </div>
</template>

<style lang="scss" module>
.input {
    width: 100%;
}
</style>
